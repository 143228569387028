import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { cloneDeep, uniqBy } from 'lodash-es';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { RuntimeService } from '../../running-area/services/runtime.service';
import { Constants } from '../../shared/constants';
import { DatasourceType } from '../../shared/enums/datasource-type.enum';
import { ModificationTypes } from '../../shared/enums/modification-types.enum';
import { DatasourceDataElement } from '../../shared/interfaces/datasource-data-element';
import { DatasourceDataResponse } from '../../shared/interfaces/datasource-data-response';
import {
  IRepresentativeMolecule,
} from '../../shared/representative-molecule/interfaces/representative-molecule.interface';
import { CobbleService } from '../../shared/representative-molecule/services/cobble.service';
import { CommunicationService } from '../../shared/services/communication.service';
import { ErrorMessengerService } from '../../shared/services/error-messenger.service';
import { SnackerService } from '../../shared/services/snacker.service';
import { ApiDataService } from './api-data.service';
import { CacheService } from './cache.service';
import { GenericDialogService } from './generic-dialog.service';
import { ToolsService } from './tools.service';

@Injectable({
  providedIn: 'root',
})
export class DataManagementService extends ApiDataService {
  public dataSourceDataRequest: any = {
    ignoredIndex: false,
    contexts: [],
    translationIds: [],
    offset: 0,
    operationType: 'row',
    relatedValue: null,
    searchFilter: null,
    limits: [],
    pageNumber: null,
    pageSize: null,
    dataFilters: [],
    error: false,
  };
  
  public dataSourceValueMetadataKey: any = {};
  dataItemsToAdd = [];
  dataItemsToAddKeys = [];
  dataItemsToAddKeysByRequest = {};
  dataItemsToUpdate = [];
  dataItemsToUpdateKeys = [];
  dataItemsToUpdateKeysByRequest = {};
  dataItemsToDelete = [];
  dataItemsToDeleteKeys = [];
  dataItemsToDeleteKeysByRequest = {};
  inDebounce = null;
  deleteInDebounce = null;
  addInDebounce = null;
  updateInDebounce = null;
  clientDataElements = [
    'LeapXL::App::Mobile Mode',
    'LeapXL::App::Desktop Mode',
    'LeapXL::App::Preview',
    'LeapXL::App::Publish',
    'LeapXL::App::Public',
    'LeapXL::App::Display Stretch',
    'LeapXL::App::Display Squish',
    'LeapXL::App::Floating Workgroups',
    'LeapXL::App::Background Image',
    'LeapXL::App::App Disabled',
    'LeapXL::App::App Name',
    'LeapXL::App::Description',
    'LeapXL::App::Start View',
    'LeapXL::App::Loader Setting',
    'LeapXL::App::Background Image',
    'LeapXL::App::Background Color',
    'LeapXL::App::Launch URL',
    // 'LeapXL::App::Current Version',
  ];
  
  _communicationService: CommunicationService;
  _cacheService: CacheService;
  _toolService: ToolsService;
  _snackerService: SnackerService;
  _dialogService: GenericDialogService;
  _runtimeService: RuntimeService;
  
  // region EVENTS DS
  eventsQueue = {};
  
  // end region
  
  constructor(
    http: HttpClient,
    errorMessengerService: ErrorMessengerService,
    communicationService: CommunicationService,
    cacheService: CacheService,
    toolService: ToolsService,
    snackerService: SnackerService,
    dialogService: GenericDialogService,
    runtimeService: RuntimeService,
    cobbleService: CobbleService,
  ) {
    super('datasources', http, errorMessengerService);
    this.cobbleService = cobbleService;
    this._communicationService = communicationService;
    this._cacheService = cacheService;
    this._toolService = toolService;
    this._snackerService = snackerService;
    this._dialogService = dialogService;
    this._runtimeService = runtimeService;
  }
  
  AddDataItemsToDataSource(
    dataItems: {
      context: string;
      translationId: number;
      value: string;
      source: string;
    }[],
    key = '',
    repMolecule: IRepresentativeMolecule,
    eventKey,
  ) {
    eventKey = this.cobbleService.Cobble.properties.newDataSubmitMechanism ? eventKey : null;
    let proccedWithIncompleteDataItems = false;
    
    if (eventKey && eventKey !== '') {
      const eventDS = this._runtimeService.EventsDS[eventKey];
      this.eventsQueue[eventKey] = this.eventsQueue[eventKey] || {
        add: { count: 0, dataItems: [], keys: [], proccedInterval: null },
        update: { count: 0, dataItems: [], keys: [], proccedInterval: null },
        delete: { count: 0, dataItems: [], keys: [], proccedInterval: null },
      };
      
      const eventQueue = this.eventsQueue[eventKey].add;
      if (dataItems && dataItems.length > 0) {
        eventQueue.dataItems = eventQueue.dataItems.concat(dataItems);
        
        if (this._toolService.BreakContext(
          dataItems[0].context).dataSourceType !== DatasourceType.LocalDataStore) {
          eventQueue.count++;
        }
        
        clearInterval(eventQueue.proccedInterval);
      } else {
        proccedWithIncompleteDataItems = true;
      }
      
      if (key && key !== '') {
        if (!eventQueue.keys.includes(key)) {
          eventQueue.keys.push(key);
        }
      }
      
      if (eventDS.addMolecules.length === eventQueue.count || proccedWithIncompleteDataItems) {
        // process data
        
        const requestID = this._toolService.GenerateGuid();
        this.dataItemsToAddKeysByRequest[requestID] = cloneDeep(eventQueue.keys);
        
        this.eventsQueue[eventKey].add = {
          count: 0,
          dataItems: [],
          keys: [],
          proccedInterval: null,
        };
        
        this.ModifyDataSourceData(requestID, ModificationTypes.Insert, eventQueue.dataItems, repMolecule)
        .subscribe((data) => {
          if ((data as any).error) {
            this._communicationService.Event.Runtime.Data.$ModifyDataResult.emit({
              keys: this.dataItemsToAddKeysByRequest[requestID],
              dataElements: [],
            });
          } else {
            this._communicationService.Event.Runtime.Data.$ModifyDataResult.emit({
              keys: this.dataItemsToAddKeysByRequest[data.requestId],
              dataElements: data.dataElements,
            });
          }
        });
        this.dataItemsToAdd = [];
      } else {
        // continue waiting
        console.log('waiting', eventDS.addMolecules.length);
        console.log('added', eventQueue.count);
        
        eventQueue.proccedInterval = setTimeout(() => {
          this.AddDataItemsToDataSource(null, key, repMolecule, eventKey);
        }, 100);
      }
    } else {
      if (this.dataItemsToAdd.length === 0) {
        this.dataItemsToAddKeys = [];
      }
      
      console.log('dataItemsToAddKeys', this.dataItemsToAddKeys);
      
      this.dataItemsToAdd = this.dataItemsToAdd.concat(dataItems);
      if (key && key !== '') {
        this.dataItemsToAddKeys.push(key);
      }
      this.AddThrottle(
        (func, delay, context) => {
          const requestID = this._toolService.GenerateGuid();
          this.dataItemsToAddKeysByRequest[requestID] = cloneDeep(this.dataItemsToAddKeys);
          this.ModifyDataSourceData(requestID, ModificationTypes.Insert, this.dataItemsToAdd, repMolecule)
          .subscribe((data) => {
            console.log(data);
            
            if ((data as any).error) {
              this._communicationService.Event.Runtime.Data.$ModifyDataResult.emit({
                keys: this.dataItemsToAddKeysByRequest[requestID],
                dataElements: [],
              });
            } else {
              this._communicationService.Event.Runtime.Data.$ModifyDataResult.emit({
                keys: this.dataItemsToAddKeysByRequest[data.requestId],
                dataElements: data.dataElements,
              });
            }
          });
          this.dataItemsToAdd = [];
        },
        dataItems.length === 1 && this._toolService.BreakContext(
          dataItems[0].context).dataSourceType === DatasourceType.LocalDataStore ? 0 : 500,
        this,
        null,
      );
    }
  }
  
  UpdateDataItemsForDataSource(
    dataItems: {
      context: string;
      translationId: number;
      value: string;
      source: string;
    }[],
    key = '',
    repMolecule: IRepresentativeMolecule,
    eventKey,
  ) {
    if (!this.cobbleService.Cobble.running) {
      return;
    }
    
    eventKey = null;
    if (eventKey && eventKey !== '') {
      const eventDS = this._runtimeService.EventsDS[eventKey];
      this.eventsQueue[eventKey] = this.eventsQueue[eventKey] || {
        add: { count: 0, dataItems: [], keys: [] },
        update: { count: 0, dataItems: [], keys: [] },
        delete: { count: 0, dataItems: [], keys: [] },
      };
      
      const eventQueue = this.eventsQueue[eventKey].update;
      eventQueue.dataItems = eventQueue.dataItems.concat(dataItems);
      eventQueue.count++;
      if (key && key !== '') {
        eventQueue.keys.push(key);
      }
      
      if (eventDS.addMolecules.length === eventQueue.count) {
        // process data
        
        const requestID = this._toolService.GenerateGuid();
        this.dataItemsToAddKeysByRequest[requestID] = cloneDeep(eventQueue.keys);
        
        this.ModifyDataSourceData(requestID, ModificationTypes.Insert, eventQueue.dataItems, repMolecule)
        .subscribe((data) => {
          console.log(data);
          
          this.eventsQueue[eventKey].update = {
            count: 0,
            dataItems: [],
            keys: [],
          };
          if ((data as any).error) {
            this._communicationService.Event.Runtime.Data.$ModifyDataResult.emit({
              keys: this.dataItemsToAddKeysByRequest[requestID],
              dataElements: [],
            });
          } else {
            this._communicationService.Event.Runtime.Data.$ModifyDataResult.emit({
              keys: this.dataItemsToAddKeysByRequest[data.requestId],
              dataElements: data.dataElements,
            });
          }
        });
        this.dataItemsToAdd = [];
      } else {
        // continue waiting
      }
    } else {
      if (this.dataItemsToUpdate.length === 0) {
        this.dataItemsToUpdateKeys = [];
      }
      
      this.dataItemsToUpdate = this.dataItemsToUpdate.concat(dataItems);
      if (key && key !== '') {
        this.dataItemsToUpdateKeys.push(key);
      }
      this.UpdateThrottle(
        (func, delay, context) => {
          console.log('dataItemsToUpdate', this.dataItemsToUpdate);
          const requestID = this._toolService.GenerateGuid();
          this.dataItemsToUpdateKeysByRequest[requestID] = cloneDeep(this.dataItemsToUpdateKeys);
          this.ModifyDataSourceData(
            requestID,
            ModificationTypes.Update,
            this.EvaluateDestructiveOperation('Update', this.dataItemsToUpdate),
            repMolecule,
          )
          .subscribe((data) => {
            this._communicationService.Event.Runtime.Data.$ModifyDataResult.emit({
              keys: this.dataItemsToUpdateKeysByRequest[data.requestId],
              dataElements: data.dataElements,
            });
          });
          this.dataItemsToUpdate = [];
        },
        dataItems.length === 1 && this._toolService.BreakContext(
          dataItems[0].context).dataSourceType === DatasourceType.LocalDataStore ? 0 : 300,
        this,
        null,
      );
    }
  }
  
  DeleteDataItemsForDataSource(
    dataItems: {
      context: string;
      translationId: number;
      value: string;
      source: string;
    }[],
    key = '',
    repMolecule: IRepresentativeMolecule,
    eventKey,
  ) {
    if (!this.cobbleService.Cobble.running) {
      return;
    }
    
    eventKey = null;
    if (eventKey && eventKey !== '') {
      const eventDS = this._runtimeService.EventsDS[eventKey];
      this.eventsQueue[eventKey] = this.eventsQueue[eventKey] || {
        add: { count: 0, dataItems: [], keys: [] },
        update: { count: 0, dataItems: [], keys: [] },
        delete: { count: 0, dataItems: [], keys: [] },
      };
      
      const eventQueue = this.eventsQueue[eventKey].delete;
      eventQueue.dataItems = eventQueue.dataItems.concat(dataItems);
      eventQueue.count++;
      if (key && key !== '') {
        eventQueue.keys.push(key);
      }
      
      if (eventDS.addMolecules.length === eventQueue.count) {
        // process data
        
        const requestID = this._toolService.GenerateGuid();
        this.dataItemsToAddKeysByRequest[requestID] = cloneDeep(eventQueue.keys);
        
        this.ModifyDataSourceData(requestID, ModificationTypes.Insert, eventQueue.dataItems, repMolecule)
        .subscribe((data) => {
          console.log(data);
          
          this.eventsQueue[eventKey].delete = {
            count: 0,
            dataItems: [],
            keys: [],
          };
          if ((data as any).error) {
            this._communicationService.Event.Runtime.Data.$ModifyDataResult.emit({
              keys: this.dataItemsToAddKeysByRequest[requestID],
              dataElements: [],
            });
          } else {
            this._communicationService.Event.Runtime.Data.$ModifyDataResult.emit({
              keys: this.dataItemsToAddKeysByRequest[data.requestId],
              dataElements: data.dataElements,
            });
          }
        });
        this.dataItemsToAdd = [];
      } else {
        // continue waiting
      }
    } else {
      if (this.dataItemsToDelete.length === 0) {
        this.dataItemsToDeleteKeys = [];
      }
      
      this.dataItemsToDelete = this.dataItemsToDelete.concat(dataItems);
      if (key && key !== '') {
        this.dataItemsToDeleteKeys.push(key);
      }
      this.DeleteThrottle(
        (func, delay, context) => {
          const requestID = this._toolService.GenerateGuid();
          this.dataItemsToDeleteKeysByRequest[requestID] = cloneDeep(this.dataItemsToDeleteKeys);
          this.ModifyDataSourceData(
            requestID,
            ModificationTypes.Delete,
            this.dataItemsToDelete,
            repMolecule,
          )
          .subscribe((data) => {
            this._communicationService.Event.Runtime.Data.$ModifyDataResult.emit({
              keys: this.dataItemsToDeleteKeysByRequest[data.requestId],
              dataElements: data.dataElements,
            });
          });
          this.dataItemsToDelete = [];
        },
        400,
        this,
        null,
      );
    }
  }
  
  EvaluateDestructiveOperation(operation = 'Update', dataElements: any[]): any[] {
    const dataElementsEvaluated = [];
    const problemDataElements = [];
    
    dataElements.forEach((de) => {
      const brokenContext = this._toolService.BreakContext(de.context);
      
      if (brokenContext.dataSourceType === DatasourceType.Spreadsheet && de.context.split(
        Constants.ContextSeparator).length === 2) {
        dataElementsEvaluated.push(de);
        // spreadsheet, do not warn
      } else {
        if (
          (brokenContext.row > 0 && brokenContext.dataSourceType === DatasourceType.Spreadsheet) ||
          brokenContext.dataSourceType !== DatasourceType.Spreadsheet
        ) {
          dataElementsEvaluated.push(de);
        } else {
          problemDataElements.push(de);
        }
      }
    });
    
    // return if not problems found
    if (problemDataElements.length === 0) {
      return dataElementsEvaluated;
    }
    
    let problemDataElementsMessage = '';
    
    problemDataElements.forEach((pde) => {
      problemDataElementsMessage += `• [${ this.cobbleService.Cobble.properties.name }] -> [View: ${ pde.view }] -> [Workgroup: ${ pde.form }] -> ${ pde.source }
                                    ${ operation } Datasource Data molecule\n\n`;
    });
    
    let message = `Data destructive action has been automatically suspended, and output may be incomplete.\n
    -------------------------------- Issue --------------------------------\n
    The following 'Update Datasource Data' molecule is not properly related to an installed 'Take DataElements' molecule or the molecule did not receive any incoming data:\n\n`;
    message += problemDataElementsMessage;
    message += `-------------------------------- Solution --------------------------------\n
    Trigger a new 'Take DataElement' molecule or change the event of an existing one so that it is triggered
    in the same session but before the above '${ operation } Datasource Data' molecule.

    Use the data reference as the datasource element your new 'Take DataElements' molecule.

    This is easy to correct and we are happy to help.`;
    
    this._dialogService.OpenConfirmDialog({
      title: `WARNING - APP DESIGN CORRECTION NEEDED`,
      message: message,
      confirmText: 'I understand or will call for assistance',
      cancelText: '',
      centerText: true,
    });
    
    return dataElementsEvaluated;
  }
  
  ModifyDataSourceData(
    requestID: string,
    modificationType: number,
    dataItems: {
      context: string;
      translationId: number;
      value: any;
      source: string;
    }[],
    repMolecule: IRepresentativeMolecule,
  ): Observable<{
    requestId: string;
    dataElements: DatasourceDataElement[];
  }> {
    // console.log('modifying collection...');
    // @ts-ignore
    
    const startFrom = new Date().getTime();
    const start = new Date().toLocaleTimeString();
    
    const localStoreDataItems = dataItems.filter(
      (di) => this._toolService.BreakContext(di.context).dataSourceType === DatasourceType.LocalDataStore);
    dataItems = dataItems.filter(
      (di) => this._toolService.BreakContext(di.context).dataSourceType !== DatasourceType.LocalDataStore);
    
    if (localStoreDataItems.length > 0) {
      let dataElements = [];
      localStoreDataItems.forEach((ldi) => {
        switch (this._toolService.GetObjectType(ldi.value)) {
          case 'array':
            ldi.value.forEach((v, index) => {
              if (this._toolService.GetObjectType(v) === 'string' || this._toolService.GetObjectType(
                v) === 'number') {
                ldi.value[index] = {
                  affectedBy: [],
                  affectingTo: [],
                  col: 1,
                  comment: null,
                  context: ldi.context,
                  dataKey: ldi.context,
                  formattedValue: v,
                  formula: null,
                  row: 1,
                  value: v.toString(),
                };
              } else {
                v.dataKey = ldi.context;
              }
            });
            
            // dataElements = dataElements.concat(ldi.value);
            dataElements = ldi.value;
            
            break;
          default:
            dataElements = [
              {
                affectedBy: [],
                affectingTo: [],
                col: 1,
                comment: null,
                context: ldi.context,
                dataKey: ldi.context,
                formattedValue: ldi.value,
                formula: null,
                row: 1,
                value: ldi.value,
              },
            ];
            break;
        }
        
        const context = ldi.context.replace(Constants.ContextSeparator, '-');
        this._cacheService.GetAsync(context)
        .subscribe((storedData) => {
          switch (modificationType) {
            case ModificationTypes.Insert:
              if (storedData) {
                const newData = {
                  dataElements: storedData.dataElements.concat(dataElements),
                };
                this._cacheService.Store(context, newData);
              } else {
                this._cacheService.Store(context, { dataElements });
              }
              break;
            case ModificationTypes.Update:
              this._cacheService.Store(context, { dataElements });
              break;
            case ModificationTypes.Delete:
              this._cacheService.Store(context, null);
              break;
          }
        });
      });
      
      if (dataItems.length === 0) {
        dataElements = uniqBy(dataElements, 'context');
        return of({
          requestId: requestID,
          dataElements: dataElements,
        });
      }
    }
    
    return this.http
    .post(
      this.apiEndpointUrl + '/ProcessDataItems',
      this.AddAuditInfoToRequest({
        dataItems: dataItems,
        modificationType,
        requestID: requestID,
      }),
    )
    .pipe(
      map((response) => {
        const datasourceResponse = response as DatasourceDataResponse;
        
        if (datasourceResponse.metaData.apiError) {
          this.DisplayAuthorApiErrorMessage(datasourceResponse.metaData.apiException,
            datasourceResponse.metaData.apiExceptionDescription);
        }
        
        this._runtimeService.AddRequestLog(
          repMolecule.Id.toString(),
          repMolecule.Properties.name,
          'ProcessDataItems',
          {
            dataItems: dataItems,
            modificationType,
            requestID: requestID,
          },
          response,
          start,
          (new Date().getTime() - startFrom).toLocaleString(),
        );
        
        return datasourceResponse;
      }),
      catchError((err) => {
        this.errorMessengerService.HandleError(err, `Error processing data`);
        return of(err);
      }),
    );
  }
  
  GetDataSourceData(dataKey: string, storedDataKey = null, repMolecule: IRepresentativeMolecule = null): Observable<DatasourceDataResponse> {
    const requestParameters = this.dataSourceDataRequest[dataKey];
    // if new set of data always default to page 1
    if (storedDataKey === null) {
      // requestParameters.pageNumber = 1;
    }
    
    let isLocalDataStore = false;
    let key = null;
    
    if (
      requestParameters &&
      requestParameters.contexts &&
      requestParameters.contexts.length === 1 &&
      requestParameters.contexts[0].length === 1 &&
      this._toolService.BreakContext(
        requestParameters.contexts[0][0]).dataSourceType === DatasourceType.LocalDataStore
    ) {
      key = requestParameters.contexts[0][0].replace(Constants.ContextSeparator, '-');
      isLocalDataStore = true;
    } else {
      key = storedDataKey
        ? storedDataKey.includes(DatasourceType.LocalDataStore)
          ? storedDataKey.replaceAll(Constants.ContextSeparator, '-')
          : `${ storedDataKey }${ requestParameters.pageNumber }${ requestParameters.searchFilter }$`
        : `${ requestParameters.contexts.join('-') }${ requestParameters.translationIds.join(
          '-') }${ requestParameters.offset }${
          requestParameters.searchFilter
        }${ requestParameters.limits.join(
          '-') }${ requestParameters.relatedValue }${ requestParameters.operationType }${
          requestParameters.pageNumber
        }${ this._toolService.HashCode(
          requestParameters.dataFilters) }${ requestParameters.pageSize }${ requestParameters.pageNumber }${
          requestParameters.searchFilter
        }${ requestParameters.ignoredIndex }`;
    }
    
    if ((isLocalDataStore || this.cobbleService.Cobble.properties.enableCache) && this._cacheService.IsCached(
      key)) {
      return this._cacheService.GetAsync(key);
    } else {
      if (isLocalDataStore) {
        return of({
          dataElements: [
            {
              col: 0,
              row: 0,
              value: null,
              formattedValue: '',
              context: requestParameters.contexts[0][0],
              key: '',
            },
          ],
          dataKey: '',
          metaData: {
            applicationId: this.cobbleService.Cobble.id,
            contexts: requestParameters.contexts,
            dataFilters: [],
            dataKey: null,
            error: false,
            ignoreFormulaText: true,
            ignoredIndex: null,
            indexes: [],
            isRange: false,
            limits: [],
            offset: 0,
            operationType: '1',
            pageCount: 1,
            pageNumber: 1,
            pageSize: 1,
            relatedValue: null,
            relatedValueIndexes: [],
            searchFilter: '',
            totalCols: 0,
            totalItemCount: 1,
            value: '',
            filterIndex: 1,
            filterValue: '',
            translationIds: requestParameters.translationIds,
            apiError: false,
            apiException: '',
            apiExceptionDescription: '',
          },
        });
      }
      if (requestParameters.contexts && this.AreAllClientDataElements(requestParameters.contexts)) {
        const dataElements = [];
        
        requestParameters.contexts.flat()
        .forEach((context) => {
          if (this.clientDataElements.includes(context)) {
            dataElements.push(this.GetClientDatsourceDataValue(context));
          }
        });
        
        const clientResponse = {
          dataElements: dataElements,
          dataKey: '',
          metaData: {
            applicationId: this.cobbleService.Cobble.id,
            contexts: requestParameters.contexts,
            dataFilters: [],
            dataKey: null,
            error: false,
            ignoreFormulaText: true,
            ignoredIndex: null,
            indexes: [],
            isRange: false,
            limits: [],
            offset: 0,
            operationType: '1',
            pageCount: 1,
            pageNumber: 1,
            pageSize: 1,
            relatedValue: null,
            relatedValueIndexes: [],
            searchFilter: '',
            totalCols: 0,
            totalItemCount: 1,
            value: '',
            filterIndex: 1,
            filterValue: '',
            translationIds: requestParameters.translationIds,
            apiError: false,
            apiException: '',
            apiExceptionDescription: '',
          },
        };
        
        return of(clientResponse);
      } else {
        const data = {
          contexts: requestParameters.contexts,
          translationIds: requestParameters.translationIds,
          offset: requestParameters.offset,
          operationType: requestParameters.operationType,
          relatedValue: requestParameters.relatedValue,
          relatedValueIndexes: requestParameters.relatedValueIndexes,
          searchFilter: requestParameters.searchFilter,
          limits: requestParameters.limits,
          pageNumber: +requestParameters.pageNumber || Constants.Defaults.InitPageNumber,
          pageSize: requestParameters.pageSize,
          dataFilters: requestParameters.dataFilters,
          ignoredIndex: requestParameters.ignoredIndex ? 1 : null,
          dataKey: storedDataKey,
        };
        
        const startFrom = new Date().getTime();
        const start = new Date().toLocaleTimeString();
        
        return this.http.post(`${ this.apiEndpointUrl }/GetDataElements`, data)
        .pipe(
          tap((result: DatasourceDataResponse) => {
            this._runtimeService.AddRequestLog(
              repMolecule ? repMolecule.Id.toString() : '',
              repMolecule ? repMolecule.Properties.name : 'System',
              'GetDataElements',
              data,
              result,
              start,
              (new Date().getTime() - startFrom).toLocaleString(),
            );
            
            this._cacheService.Store(key, result);
            this._cacheService.StoreDataSetValues(result.dataElements);
            
            if (result.metaData.error) {
              if (requestParameters.contexts) {
                const contextDs = requestParameters.contexts.length > 0 ? requestParameters.contexts[0][0] : '';
                const spplitedContext = contextDs.split(Constants.ContextSeparator);
                spplitedContext.pop();
                this._snackerService.ShowMessageOnBottom(
                  `Unable to obtain data from the DataSource (${ this._runtimeService.ProductionApp ? 'Publish' : 'Preview' }) ${ spplitedContext.join(
                    Constants.ContextSeparator,
                  ) }`, 'warning');
                
              }
            }
            
            if (result.metaData.apiError) {
              this.DisplayAuthorApiErrorMessage(result.metaData.apiException,
                result.metaData.apiExceptionDescription);
            }
            
            if (requestParameters.contexts) {
              result.metaData.contexts.forEach((contextGroup) => {
                contextGroup.forEach((context) => {
                  if (this.clientDataElements.includes(context)) {
                    result.dataElements.push(this.GetClientDatsourceDataValue(context));
                  }
                });
              });
            }
            
            // console.log('get data result', result.metaData.contexts);
            
            return result;
          }),
          map((response: any) => {
            // console.log(response);
            
            return response;
            // return response.dataElements as [];
          }),
          catchError((error) =>
            this.errorMessengerService.HandleError(
              error,
              `Error getting datasource data from: ${ requestParameters.contexts.join(
                '-') }, translation ids: ${ requestParameters.translationIds.join(
                '-',
              ) }.`,
              requestParameters,
            ),
          ),
        );
      }
    }
  }
  
  AreAllClientDataElements(contextsGroup: string[][]): boolean {
    let allDataElements = true;
    
    contextsGroup.forEach((contexts) => {
      contexts.forEach((c) => {
        const is = this.IsClientDataElement(c);
        if (!is) {
          allDataElements = false;
        }
      });
    });
    return allDataElements;
  }
  
  IsClientDataElement(context: string): boolean {
    return this.clientDataElements.includes(context);
  }
  
  GetClientDatsourceDataValue(context: string): {
    col: number;
    row: number;
    value: string;
    formattedValue: string;
    context: string;
    key: string;
  } {
    const dataElement = {
      col: 0,
      row: 0,
      value: null,
      formattedValue: '',
      context: context,
      key: '',
    };
    
    switch (context) {
      case 'LeapXL::User::Name':
        dataElement.value = this._toolService.IsMobile ? 'Y' : 'N';
        dataElement.formattedValue = this._toolService.IsMobile ? 'Y' : 'N';
        break;
      case 'LeapXL::App::Mobile Mode':
        dataElement.value = dataElement.formattedValue = this._toolService.IsMobile ? 'Y' : 'N';
        break;
      case 'LeapXL::App::Desktop Mode':
        dataElement.value = dataElement.formattedValue = this._toolService.IsMobile ? 'N' : 'Y';
        break;
      case 'LeapXL::App::Preview':
        dataElement.value = dataElement.formattedValue = this.cobbleService.Cobble.preview ? 'Y' : 'N';
        break;
      case 'LeapXL::App::Publish':
        dataElement.value = dataElement.formattedValue = this.cobbleService.Cobble.isRunnable ? 'Y' : 'N';
        break;
      case 'LeapXL::App::Public':
        dataElement.value = dataElement.formattedValue = this.cobbleService.Cobble.isPublic ? 'Y' : 'N';
        break;
      case 'LeapXL::App::Display Stretch':
        dataElement.value = dataElement.formattedValue = this.cobbleService.Cobble.deviceConfigurations[this.cobbleService.Cobble.deviceType]
          .maxSizeLock
          ? 'Y'
          : 'N';
        break;
      case 'LeapXL::App::Display Squish':
        dataElement.value = dataElement.formattedValue = this.cobbleService.Cobble.deviceConfigurations[this.cobbleService.Cobble.deviceType]
          .minSizeLock
          ? 'Y'
          : 'N';
        break;
      case 'LeapXL::App::Floating Workgroups':
        dataElement.value = dataElement.formattedValue = this.cobbleService.Cobble.deviceConfigurations[this.cobbleService.Cobble.deviceType].float
          ? 'Y'
          : 'N';
        break;
      case 'LeapXL::App::Background Image':
        dataElement.value = dataElement.formattedValue = this.cobbleService.Cobble.properties.imageUrl;
        break;
      case 'LeapXL::App::App Name':
        dataElement.value = dataElement.formattedValue = this.cobbleService.Cobble.properties.name;
        break;
      case 'LeapXL::App::Description':
        dataElement.value = dataElement.formattedValue = this.cobbleService.Cobble.properties.description;
        break;
      case 'LeapXL::App::Start View':
        dataElement.value = dataElement.formattedValue = this.cobbleService.Cobble.properties.views.find(
          (v) => v.id === this.cobbleService.Cobble.properties.mainView,
        ).name;
        break;
      case 'LeapXL::App::Loader Setting':
        dataElement.value = dataElement.formattedValue = this.cobbleService.Cobble.loader;
        break;
      case 'LeapXL::App::Background Color':
        dataElement.value = dataElement.formattedValue = this.cobbleService.Cobble.properties.color;
        break;
      case 'LeapXL::App::Launch URL':
        dataElement.value = dataElement.formattedValue = `${ document.location.protocol }//${ document.location.hostname }${
          document.location.hostname.search('localhost') > -1 ? ':4200' : ''
        }/run/${ this.cobbleService.Cobble.companySlug || '' }/${ this.cobbleService.Cobble.slug }`;
        break;
      case 'LeapXL::App::Current Version':
        dataElement.value = dataElement.formattedValue = this.cobbleService.Cobble.version;
        break;
      
      default:
        break;
    }
    
    return dataElement;
  }
  
  DisplayAuthorApiErrorMessage(exception: string, exceptionDescription: string) {
    const isEditor = this.cobbleService.Cobble && !this.cobbleService.Cobble.running;
    const isPreview = this.cobbleService.Cobble && this.cobbleService.Cobble.running && this.cobbleService.Cobble.preview;
    const isPublish = this.cobbleService.Cobble && this.cobbleService.Cobble.running && !this.cobbleService.Cobble.preview;
    
    if (isEditor || isPreview) {
      this._dialogService.OpenConfirmDialog({
        title: `API system report`,
        message: `${ exception } \n\n ${ exceptionDescription }`,
        confirmText: 'Ok',
        cancelText: '',
        centerText: false,
      });
    } else if (isPublish) {
      this._snackerService.ShowMessageOnBottom(
        '\'There was an error processing API data, please see audits for details\'', 'warning');
    }
  }
  
  GetDataSourceRequestForKey(dataKey: string) {
    return (
      this.dataSourceDataRequest[dataKey] || {
        contexts: [],
        translationIds: [],
        offset: 0,
        operationType: 'row',
        relatedValue: null,
        searchFilter: null,
        limits: [],
        pageNumber: null,
        pageSize: null,
        dataFilters: [],
        error: false,
        ignoredIndex: false,
      }
    );
  }
  
  ClearDataSourceDataRequest(busId: string) {
    this.dataSourceDataRequest[busId] = {
      contexts: [],
      translationIds: [],
      offset: 0,
      operationType: 'row',
      relatedValue: null,
      searchFilter: null,
      limits: [],
      pageNumber: null,
      pageSize: null,
      dataFilters: [],
      error: false,
      ignoredIndex: false,
    };
  }
  
  Throttle(func, delay, context, args) {
    clearTimeout(this.inDebounce);
    this.inDebounce = setTimeout(() => func.apply(context, arguments), delay);
  }
  
  DeleteThrottle(func, delay, context, args) {
    clearTimeout(this.deleteInDebounce);
    this.deleteInDebounce = setTimeout(() => func.apply(context, arguments), delay);
  }
  
  AddThrottle(func, delay, context, args) {
    clearTimeout(this.addInDebounce);
    this.addInDebounce = setTimeout(() => func.apply(context, arguments), delay);
  }
  
  UpdateThrottle(func, delay, context, args) {
    clearTimeout(this.updateInDebounce);
    this.updateInDebounce = setTimeout(() => func.apply(context, arguments), delay);
  }
}
